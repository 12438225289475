import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import "core-js/modules/es6.function.name.js";
import { defineStore } from "pinia";
import { useQuery } from "@vue/apollo-composable";
import { MeAuthDocument } from "~/graphql/generated/graphql";
import { useAmplitudeStore } from "./amplitude";
export var useUserStore = defineStore("user", function () {
  var _useAmplitudeStore = useAmplitudeStore(),
    identify = _useAmplitudeStore.identify;
  var user = ref();
  var me = useQuery(MeAuthDocument);
  var org = computed(function () {
    var _me$result$value, _me$result$value$me;
    if (!((_me$result$value = me.result.value) !== null && _me$result$value !== void 0 && (_me$result$value$me = _me$result$value.me) !== null && _me$result$value$me !== void 0 && _me$result$value$me.organization)) return null;
    return {
      id: me.result.value.me.organization.id,
      name: me.result.value.me.organization.name
    };
  });
  watch([user, org], function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      user = _ref2[0],
      org = _ref2[1];
    if (org && user) identify(user, org);
  }, {
    immediate: true
  });
  return {
    user: user,
    org: org
  };
});
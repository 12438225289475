import type translationsDA from "~/translations/da.json";
import type translationsEN from "~/translations/en.json";

export const useThrottle = (func: (...args: any) => any, limit: number) => {
  let inThrottle: boolean = false;

  return function (this: any, ...args: any[]) {
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  };
};

export const arrayLast = <T, D extends T | undefined>(arr: T[], defVal?: D): D extends undefined ? T | undefined : T => arr[arr.length - 1] ?? (defVal as T);

export const hasValue = <T extends unknown>(v: T): v is NonNullable<T> => typeof v !== "undefined" && v !== null;

export const filterWithValue = <T>(arr: Nullable<T>[]) => arr.filter(hasValue) as T[];
export const filterUnique = <T extends string | number>(arr: T[]) => arr.filter((item, index, arr) => arr.indexOf(item) === index);

type MapReduceAllHandler<T, R> = (index: number, ...items: T[]) => R;
type MapReduceAllArgs<T, R> = [...arrays: T[][], handler: MapReduceAllHandler<T, R>];

export const mapReduceAll = <T, R>(...args: MapReduceAllArgs<T, R>) => {
  const arrays = args.slice(0, args.length - 1) as T[][];
  const handler = arrayLast(args) as MapReduceAllHandler<T, R>;
  const length = arrays[0].length;

  if (length === 0) return [];

  if (!arrays.every((arr) => arr.length === length)) {
    throw "mapAll array length mismatch";
  }

  const reduced: R[] = Array(length);

  for (let i = 0; i < length; i++) {
    reduced[i] = handler(i, ...arrays.map((arr) => arr[i]));
  }

  return reduced;
};

export const arrayMutateToggleValue = <T>(arr: T[], v: T, accessor?: (item: T) => boolean) => {
  const index = accessor ? arr.findIndex(accessor) : arr.indexOf(v);

  if (index === -1) arr.push(v);
  else arr.splice(index, 1);

  return arr;
};

export const sleep = (ms: number) => new Promise<void>((res) => setTimeout(res, ms));

class NaNError extends Error {
  override message: string = "Not a number";
}

export const prepareNumber = (number: Nullable<number | string>) => {
  if (!hasValue(number) || number === Infinity || number === -Infinity) throw new NaNError();

  if (typeof number === "string") number = parseFloat(number);

  if (isNaN(number)) throw new NaNError();

  return number;
};

export const formatString = (string: string | null | undefined, formatter: (string: string) => string) => {
  if (!hasValue(string)) return "–";

  return formatter(string);
};

export const formatNumber = (number: number | string | null | undefined, formatter: (number: number) => string) => {
  try {
    return formatter(prepareNumber(number));
  } catch (e) {
    if (e instanceof NaNError) return "–";
    else throw e;
  }
};

export type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export const getEntries = <T extends object>(obj: T) => Object.entries(obj) as Entries<T>;

export type TranslationKey = keyof typeof translationsDA & keyof typeof translationsEN;

export type UIColor = `--${string}-${"white" | `${string}-${number}`}`;

/** colors */
export type color = { r: number; g: number; b: number };
export const clamp = (min: number, max: number, v: number) => Math.min(Math.max(v, min), max);
export const clampNorm = (min: number, max: number, v: number) => (clamp(min, max, v) - min) / (max - min);
export const mul = (color: color, f: number) => ({ r: color.r * f, g: color.g * f, b: color.b * f });
export const mix = (...colors: color[]) => ({
  r: colors.reduce((prev, next) => prev + next.r, 0),
  g: colors.reduce((prev, next) => prev + next.g, 0),
  b: colors.reduce((prev, next) => prev + next.b, 0),
});

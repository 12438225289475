import moment from "moment";
import { mapboxLayers } from "../components/map/mapboxLayers";
import { MetricPeriodType } from "~/graphql/generated/graphql";
import { TenanciesUserPreferenceColumnGroups, TenanciesUserPreferenceColumns, type TenanciesDate } from "./portfolio/portfolio-tenancy-helpers";
import type { BalanceDateRange } from "./portfolio/portfolio-balance-helpers";
import { BenchmarkGroupOption } from "./portfolio/portfolio-opex-helpers";
import { settingsTemplateOptions } from "~/pages/portfolio/components/Expenses/Opexes/helpers";

export type TenanciesCustomFieldKey = `custom_${string}_${string}`;
export type TenanciesColumnGroupKey = (typeof TenanciesUserPreferenceColumnGroups)[number]["name"] | "custom";
export type TenanciesColumnKey = (typeof TenanciesUserPreferenceColumns)[number]["key"] | TenanciesCustomFieldKey;

/** Note Sven: Deprecated, but still used as a type for migration */
export type NamespacePortfolioPropertyTenanciesColumnSettings = {
  [k in TenanciesColumnKey]: {
    enabled: boolean;
    position: number;
  };
};

export type NamespacePortfolioPropertyTenancies = {
  selectedDate: TenanciesDate;
  columns: TenanciesColumnKey[];
};

const getDefaultTenancyColumnSettings = () => TenanciesUserPreferenceColumns.filter((header) => header.enabled).map((header) => header.key);

export enum NumberDisplay {
  Absolute = "ABSOLUTE",
  Thousands = "THOUSANDS",
  Millions = "MILLIONS",
}

export type NamespaceChrome = {
  splitPercentage: number;
  containerFocusIndex: number;
};

export type DateRangeConfig = {
  periodType: MetricPeriodType;
  dateRange: BalanceDateRange;
  customDateStart: string | undefined;
  customDateEnd: string | undefined;
};

export type BudgetsConfig = Record<string, { id: string; description?: string | null; validFrom?: string | null }[]>;

export type NamespacePortfolioPortfolio = {
  churnFilterDateTo: string;
  churnFilterDateFrom: string;
  churnFilterPeriodType: MetricPeriodType;

  idleTenancyPercentFilterDateTo: string;
  idleTenancyPercentFilterDateFrom: string;
  idleTenancyPercentFilterPeriodType: MetricPeriodType;

  rentFilterDateTo: string;
  rentFilterDateFrom: string;
  rentFilterPeriodType: MetricPeriodType;
};

export type NamespacePortfolioPropertyOverview = {
  idleTenancyRateFilterDateTo: string;
  idleTenancyRateFilterDateFrom: string;
  idleTenancyRateFilterPeriodType: MetricPeriodType;

  evictionRateFilterDateTo: string;
  evictionRateFilterDateFrom: string;
  evictionRateFilterPeriodType: MetricPeriodType;

  rentRateFilterDateTo: string;
  rentRateFilterDateFrom: string;
  rentRateFilterPeriodType: MetricPeriodType;
};

export type NamespacePortfolioOpex = {
  template: (typeof settingsTemplateOptions)[number]["id"];
  includeZeroRows: boolean;
  benchmark: BenchmarkGroupOption;
  periodRangeType: "yearWhole" | "yearRolling" | "yearToDate";
  periodOffset: number;
  normaliseBy: "tenancies" | "area" | "none";
  excludePropertyIds: ("properties" | "companies" | string)[];
};

export type NamespacePortfolioPropertyOpex = DateRangeConfig & {
  normaliseBy: "tenancies" | "area" | "none";
  disabledSeries: string[];
  propertyBudgets: Record<string, string[]>;
};

export type NamespacePortfolioPropertyBalance = DateRangeConfig & {
  numberDisplay: NumberDisplay;
  includeZeroAccounts: boolean;
  includeZeroCategories: boolean;
  useChangeColors: boolean;
  showBudgets: boolean;
  showBudgetForecasts: boolean;
  propertyBudgets: BudgetsConfig;
  showColumns: {
    totalCostWithVat: boolean;
    change: boolean;
    changePercentage: boolean;
  };
};

export const DEFAULTS = {
  preferenceNamespaceTableBuildings: {
    buildingNumber: true,
    address: true,
    matEjerlav: false,
    usageText: true,
    statusText: false,
    constructionYear: true,
    conservationText: false,
    noOfFloors: false,
    energyLabel: false,
    heatingInstallationText: false,
    waterSupplyText: false,
    drainageConditionText: false,
    roofingMaterialText: false,
    outerWallMaterialText: false,
    builtArea: false,
    totalAreaOfClosedShelter: false,
    buildingArea: true,
    totalBasementArea: true,
    usedBasementArea: false,
    deepBasementArea: false,
    totalAtticArea: true,
    usedAtticArea: false,
    totalOtherArea: false,
    totalResidentialArea: true,
    legalBasementResidence: false,
    totalCommercialArea: false,
    commercialBasementArea: false,
    floorsWithoutBasementAndAttic: false,
  },
  preferenceNamespaceTableCadastrals: {
    matEjerlav: true,
    status: true,
    registeredArea: true,
    preservedForestArea: true,
    noOfBuildings: true,
    noOfUnits: true,
    noOfTechnicalFacilities: true,
  },
  preferenceNamespaceTableUnits: {
    address: true,
    matEjerlav: false,
    buildingNumber: false,
    usageText: true,
    statusText: true,
    numberOfBathrooms: false,
    bathroomConditionText: false,
    numberOfWaterFlushingToilets: false,
    toiletConditionText: false,
    kitchenConditionText: false,
    residentialArea: true,
    commercialArea: true,
    openShelterArea: false,
    enclosedShelterOuthouseArea: false,
    otherArea: false,
    dlrArea: false,
    numberOfRooms: false,
    totalArea: true,
  },
  preferenceNamespaceTableTechnicalFacilities: {
    facilityNumber: true,
    classificationText: true,
    matEjerlav: false,
    constructionYear: true,
    oilTankContentText: false,
    locationText: false,
    statusText: true,
    oilTankMaterialText: false,
    oilTankSizeClassText: false,
    oilTankSize: false,
    manufacturingYear: false,
  },
  preferenceNamespaceTableCondos: {
    address: true,
    bfeNumber: true,
    condoNumber: true,
    usageText: false,
    ownersString: false,
    valuation: true,
  },
  preferenceNamespaceTablePostAddresses: {
    address: true,
    doorNumber: true,
    postalCode: true,
  },
  preferenceNamespaceTableLists: {
    address: true,
    description: true,
    status: true,
    owner: true,
    updatedAt: true,
  },
  preferenceNamespaceLoginMessages: {
    plesnerPersonLookupMessage: false,
  },
  preferenceNamespaceLocale: {
    locale: "da-dk",
  },
  preferenceNamespaceMap: {
    showPropertyPlots: true,
    showPropertyBuildings: true,
    showOrtoForaar: false,
    showSoilContaminations: false,
    showOilTanks: false,
    showPlots: true,
    showPlotNumbers: true,
    oilTanksPlacementFilter: null,
    oilTanksDisamblementFilter: null,
    poi: false,
    transit: false,
    satellite: false,
    building_number_label: false,
    ...mapboxLayers
      .flatMap((mbl) => mbl.layer as any)
      .reduce((acc, layer) => {
        acc[layer.id] = false;
        return acc;
      }, {} as any /**TODO should be typed */),
  },
  preferencesNamespaceChrome: <NamespaceChrome>{
    containerFocusIndex: 1,
    splitPercentage: 65,
  },
  preferenceNamespaceLists: {
    listView: "list",
  },
  preferenceNamespaceTableLejetjekProperty: {
    type: true,
    totalYearlyTopRent: false,
    totalYearlyAvgRent: false,
    totalYearlyBottomRent: false,
    totalYearlyBottomSquareMeterRent: true,
    totalYearlyAvgSquareMeterRent: true,
    totalYearlyTopSquareMeterRent: true,
    vacancyRate: true,
    vacancyDescription: true,
  },

  preferencesNamespacePortfolioPropertyOpex: <NamespacePortfolioPropertyOpex>{
    dateRange: "latest12",
    periodType: "MONTHLY",
    customDateEnd: undefined,
    customDateStart: undefined,
    normaliseBy: "area",
    disabledSeries: [],
    propertyBudgets: {},
  },

  preferencesNamespacePortfolioOpex: <NamespacePortfolioOpex>{
    template: "budgetYTD",
    includeZeroRows: false,
    benchmark: "average.total",
    periodOffset: 0,
    periodRangeType: "yearWhole",
    normaliseBy: "area",
    excludePropertyIds: [],
  },

  preferencesNamespacePortfolioPropertyBalance: <NamespacePortfolioPropertyBalance>{
    dateRange: "latest8",
    customDateEnd: undefined,
    customDateStart: undefined,
    periodType: "QUARTERLY",
    numberDisplay: "ABSOLUTE",
    includeZeroAccounts: false,
    includeZeroCategories: true,
    useChangeColors: false,
    showBudgets: false,
    showBudgetForecasts: false,
    propertyBudgets: {},
    showColumns: {
      totalCostWithVat: true,
      change: false,
      changePercentage: false,
    },
  },
  preferenceNamespacePortfolioPortfolio: <NamespacePortfolioPortfolio>{
    churnFilterDateTo: moment().utc().format("YYYY-MM-DD"),
    churnFilterDateFrom: moment().subtract(5, "year").utc().format("YYYY-MM-DD"),
    churnFilterPeriodType: "QUARTERLY",

    idleTenancyPercentFilterDateTo: moment().utc().format("YYYY-MM-DD"),
    idleTenancyPercentFilterDateFrom: moment().subtract(5, "year").utc().format("YYYY-MM-DD"),
    idleTenancyPercentFilterPeriodType: "QUARTERLY",

    rentFilterDateTo: moment().utc().format("YYYY-MM-DD"),
    rentFilterDateFrom: moment().subtract(5, "year").utc().format("YYYY-MM-DD"),
    rentFilterPeriodType: "QUARTERLY",
  },
  preferenceNamespacePortfolioPropertyOverview: <NamespacePortfolioPropertyOverview>{
    idleTenancyRateFilterDateTo: moment().utc().format("YYYY-MM-DD"),
    idleTenancyRateFilterDateFrom: moment().subtract(5, "year").utc().format("YYYY-MM-DD"),
    idleTenancyRateFilterPeriodType: MetricPeriodType.Quarterly,

    evictionRateFilterDateTo: moment().utc().format("YYYY-MM-DD"),
    evictionRateFilterDateFrom: moment().subtract(5, "year").utc().format("YYYY-MM-DD"),
    evictionRateFilterPeriodType: MetricPeriodType.Quarterly,

    rentRateFilterDateTo: moment().utc().format("YYYY-MM-DD"),
    rentRateFilterDateFrom: moment().subtract(5, "year").utc().format("YYYY-MM-DD"),
    rentRateFilterPeriodType: MetricPeriodType.Quarterly,
  },
  preferenceNamespacePortfolioPropertyTenancies: <NamespacePortfolioPropertyTenancies>{
    selectedDate: "today",
    columns: getDefaultTenancyColumnSettings(),
  },
} as const;

export type NamespaceName = keyof typeof DEFAULTS;
export type Namespace = (typeof DEFAULTS)[NamespaceName];

export const matchNamespace = <T extends NamespaceName>(namespace: NamespaceName): (typeof DEFAULTS)[T] => {
  const matchedNamespace = DEFAULTS[namespace];

  if (matchedNamespace === undefined) {
    throw `~/plugins/user-preferences.js - namespace: "${namespace}" not defined`;
  }

  return matchedNamespace;
};

const migrateOldTenanciesColumnSettings = (preferences?: Object) => {
  if (!preferences || "includeColumns" in preferences === false) return;

  const oldColumns = preferences as { includeColumns: { global: NamespacePortfolioPropertyTenanciesColumnSettings } };

  return Object.entries(oldColumns.includeColumns.global)
    .filter(([, value]) => value.enabled)
    .sort(([, a], [, b]) => a.position - b.position)
    .map(([key]) => key as TenanciesColumnKey);
};

const getDefaults: Partial<{ [k in NamespaceName]: (preferences: (typeof DEFAULTS)[k] | undefined) => (typeof DEFAULTS)[k] }> = {
  preferenceNamespacePortfolioPropertyTenancies: (preferences) => {
    const columnsFromOldSettings = migrateOldTenanciesColumnSettings(preferences);

    return {
      selectedDate: preferences?.selectedDate ?? "today",
      columns: preferences?.columns ?? columnsFromOldSettings ?? getDefaultTenancyColumnSettings(),
    };
  },
};

export const getNamespaceSync = <T extends NamespaceName>(namespace: T, preferences: any): Mutable<(typeof DEFAULTS)[T]> => {
  const matchedNamespace = matchNamespace(namespace);

  const namespaceCustomGet = getDefaults[namespace];

  if (namespaceCustomGet) {
    return namespaceCustomGet(preferences[namespace]);
  } else if (preferences === undefined || preferences[namespace] === undefined) {
    return matchedNamespace;
  } else {
    const output = {} as typeof matchedNamespace;
    const preferencesNamespaced = preferences[namespace];

    Object.keys(matchedNamespace).forEach((key: keyof typeof matchedNamespace) => {
      if (preferencesNamespaced[key] === undefined) {
        output[key] = matchedNamespace[key];
      } else {
        output[key] = preferencesNamespaced[key];
      }
    });

    return output;
  }
};

import moment from "moment";
import { Ref, unref } from "vue";
import { Currency } from "~/helpers/apollo/types";
import { hasValue } from "~/helpers/common-helpers";

export type FieldDropDownValue<T = any> = { value: T; name: string };

export type FieldType = Field["type"];

export type DropDownFieldType<T = any> = { value: T; name: string };

export interface BaseField<T extends string = any> {
  key: T;
  type: FieldType;
  value?: Ref<any>;
  label?: string;
  labelInput?: string;
  position?: number;
  errors?: string[];
  placeholder?: string;
  disabled?: boolean;
}

interface BaseDropDownField<T extends string = any> extends BaseField<T> {
  dropdownSettings?: {
    getLabel?: (value: string) => string;
    allowUnset?: boolean;
  };
}

export interface DropDownField<T extends string = any> extends BaseDropDownField<T> {
  type: "dropdown";
  value: Ref<Nullable<string>>;
  options: DropDownFieldType[];
}

export interface NumberField<T extends string = any> extends BaseField<T> {
  type: "number";
  value: Ref<number | string | undefined>;
}

export interface BooleanField<T extends string = any> extends BaseField<T> {
  type: "boolean";
  labelPosition?: "top" | "left" | "right";
  mode?: "dropdown" | "tickbox";
  value: Ref<boolean | undefined>;
}

export interface TextField<T extends string = any> extends BaseField<T> {
  type: "text" | "multiline";
  characterCountLimit?: number;
  value: Ref<string | undefined>;
}

export interface DateField<T extends string = any> extends BaseField<T> {
  type: "date";
  value: Ref<string | undefined>;
}

export interface EnumField<E extends Record<string, string> = Record<string, string>, T extends string = any> extends BaseDropDownField<T> {
  type: "enum";
  value: Ref<Nullable<string>>;
  enumerable: E;
}

export interface CurrencyField<T extends string = any> extends BaseField<T> {
  type: "currency";
  value: Ref<Nullable<Currency>>;
}

export type Field<T extends string = any> =
  | EnumField<Record<string, string>, T>
  | DropDownField<T>
  | NumberField<T>
  | TextField<T>
  | BooleanField<T>
  | DateField<T>
  | CurrencyField<T>;

export type NewRecord<T extends string = string> = { id: undefined; columns: (Field<T> & { editable?: boolean })[] };

export type ChangedRecord<T extends string = string> = { id: string; columns: (Field<T> & { editable?: boolean })[] };
export type UpdatedRecords<T extends string = string> = {
  newRecords: NewRecord<T>[];
  updatedRecords: ChangedRecord<T>[];
  deletedRecords: ChangedRecord<T>[];
};

export const parseFieldValue = <T extends Field>(field: T): T["value"]["value"] => {
  if (!hasValue(field.value.value)) {
    switch (field.type) {
      case "enum":
      case "dropdown":
        return field.dropdownSettings?.allowUnset ? null : undefined;
      default:
        return field.value.value;
    }
  }

  switch (field.type) {
    case "boolean":
      return !!field.value.value;
    case "date":
      return moment(field.value.value, "YYYY-MM-DD").set({ hour: 12, minute: 0, second: 0, millisecond: 0 }).toISOString();
    case "number":
      return parseFloat(field.value.value as string);
    case "dropdown":
    case "currency":
    case "enum":
    default:
      return field.value.value;
  }
};

export const mapFieldsForInput = <T extends Record<string, any>>(fields: Field<string>[]) => {
  return fields.reduce((acc, curr) => {
    acc[curr.key as keyof T] = parseFieldValue(curr) as T[typeof curr.key];

    return acc;
  }, <T>{});
};

export type EditTableDataHeader = { label: string; width?: string; textAlignEnd?: boolean; field: (row: Object) => Field & { editable?: boolean } };
